export const initialState = {
  isWetuUser: false,
  isUserAuthenticated: false,
  operatorId: -1,
  redirectUrl: ''
}

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_REDIRECT_URL': {
      return { ...state, redirectUrl: action.data }
    }
    default:
      return state
  }
}


export default accountReducer