export const reduxActions = {
  LOADING: '_LOADING',
  SUCCESS: '_SUCCESS',
  FAILURE: '_FAILURE'
}

export const supplierManagementActions = {
  GET_SUPPLIER_GRID: 'GET_SUPPLIER_GRID',
  GET_SUPPLIER_ITINERARY_USAGE: 'GET_SUPPLIER_USAGE'
}

export const noteActions = {
  GET_NOTES: 'GET_NOTES',
  ADD_NOTE: 'ADD_NOTE',
  CLEAR_NOTES: 'CLEAR_NOTES'
}

export const uiActions = {
  SET_GRID_PAGE: 'SET_GRID_PAGE',
  SET_GRID_SORT: 'SET_GRID_SORT',
  SET_GRID_FILTERS: 'SET_GRID_FILTERS',
  SET_GRID_SEARCH_NAME: 'SET_GRID_SEARCH_NAME',
  SET_ACTIVE_SUBMENU_EDITOR_CHILDREN:'SET_ACTIVE_SUBMENU_EDITOR_CHILDREN'
}
