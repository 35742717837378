import { noteActions, reduxActions } from '../../../consts/redux'

const initialState = {
  notes: [],
  loadingNotes: false,
  addingNotes: false,
  error: {}
}

const notesReducerMap = {
  [`${noteActions.GET_NOTES}${reduxActions.LOADING}`]: (state) => {
    return { ...state, ...initialState, error: {}, loadingNotes: true }
  },
  [`${noteActions.GET_NOTES}${reduxActions.SUCCESS}`]: (state, action) => {
    return { ...state, loadingNotes: false, error: {}, notes: action.data.notes }
  },
  [`${noteActions.GET_NOTES}${reduxActions.FAILURE}`]: (state, action) => {
    return { ...state, loadingNotes: false, error: action.data.error, notes: [] }
  },
  [`${noteActions.ADD_NOTE}${reduxActions.LOADING}`]: (state) => {
    return { ...state, error: {}, addingNotes: true }
  },
  [`${noteActions.ADD_NOTE}${reduxActions.SUCCESS}`]: (state, action) => {
    const notesClone = _.cloneDeep(state.notes)
    notesClone.push(action.data.note)
    return { ...state, addingNotes: false, notes: notesClone }
  },
  [`${noteActions.ADD_NOTE}${reduxActions.FAILURE}`]: (state, action) => {
    return { ...state, addingNotes: false, error: action.data.error }
  },
  [noteActions.CLEAR_NOTES]: (state) => {
    return { ...state, ...initialState }
  }
}

const notesReducer = (state = initialState, action) => {
  return notesReducerMap[action.type]
    ? notesReducerMap[action.type](state, action)
    : state
}

export default notesReducer
