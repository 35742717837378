// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import accountReducer from './account'
import toasterReducer from './toaster'
import supplierManagementReducer from './supplierGrid'
import notesReducer from './notes'
import uiReducer from './ui'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  accountReducer,
  toasterReducer,
  supplierManagementReducer,
  notesReducer,
  uiReducer
})

export default rootReducer
