import { uiActions } from '../../../consts/redux'

const initialState = {
  take: 50,
  page: 1,
  filters: [],
  sortColumn: '',
  sortDirection: '',
  searchName: '',
  subMenuChildren: []  
}

const uiReducerMap = {
  [uiActions.SET_GRID_FILTERS]: (state, action) => {
    return { ...state, filters: action.data.filters }
  },
  [uiActions.SET_GRID_PAGE]: (state, action) => {
    return { ...state, page: action.data.page }
  },
  [uiActions.SET_GRID_SORT]: (state, action) => {
    return { ...state, sortColumn: action.data.column, sortDirection: action.data.direction }
  },
  [uiActions.SET_GRID_SEARCH_NAME]: (state, action) => {
    return { ...state, searchName: action.data.searchName }
  },
  [uiActions.SET_ACTIVE_SUBMENU_EDITOR_CHILDREN]: (state, action) => {
    return { ...state, subMenuChildren: action.data } 
  }
}

const uiReducer = (state = initialState, action) => {
  return uiReducerMap[action.type]
    ? uiReducerMap[action.type](state, action)
    : state
}

export default uiReducer
