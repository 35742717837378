import { reduxActions, supplierManagementActions } from '../../../consts/redux'
const initialState = {
  isSupplierListLoading: false,
  isSupplierUsageLoading: false,
  isSupplierUsageLoaded: false,
  isSuppliersLoaded: false,
  error: {},
  supplierList: [],
  pagination: { start: null, end: null, total: null }
}

const supplierManagementReducerMap = {
  [`${supplierManagementActions.GET_SUPPLIER_GRID}${reduxActions.LOADING}`]: (state) => {
    return { ...state, error: {}, isSupplierListLoading: true }
  },
  [`${supplierManagementActions.GET_SUPPLIER_GRID}${reduxActions.SUCCESS}`]: (state, action) => {
    return { ...state, isSupplierListLoading: false, error: {}, supplierList: action.data.supplierList, pagination: action.data.pagination, isSupplierUsageLoaded: false, isSuppliersLoaded: true }
  },
  [`${supplierManagementActions.GET_SUPPLIER_GRID}${reduxActions.FAILURE}`]: (state, action) => {
    return { ...state, isSupplierListLoading: false, error: action.data.error, isSuppliersLoaded: true }
  },
  [`${supplierManagementActions.GET_SUPPLIER_ITINERARY_USAGE}${reduxActions.LOADING}`]: (state) => {
    return { ...state, isSupplierUsageLoading: true }
  },
  [`${supplierManagementActions.GET_SUPPLIER_ITINERARY_USAGE}${reduxActions.SUCCESS}`]: (state, action) => {
    const supplierClone = _.cloneDeep(state.supplierList)
    supplierClone.forEach(x => {
      if (action.data.usageItinerary[x.id]) {
        x.itinerary = action.data.usageItinerary[x.id]
      } else {
        x.itinerary = {
          overAllUsage: 0,
          myUsage: 0,
          rebrandedUsage: 0
        }
      }
    })

    return { ...state, isSupplierUsageLoading: false, isSupplierUsageLoaded: true, supplierList: supplierClone }
  },
  [`${supplierManagementActions.GET_SUPPLIER_ITINERARY_USAGE}${reduxActions.FAILURE}`]: (state) => {
    return { ...state, isSupplierUsageLoading: false }
  }
}

const supplierManagementReducer = (state = initialState, action) => {
  return supplierManagementReducerMap[action.type]
    ? supplierManagementReducerMap[action.type](state, action)
    : state
}

export default supplierManagementReducer
